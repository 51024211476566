<script lang="ts">
    import MyMarket from './MyMarket.svelte';
    import { inertia } from '@inertiajs/svelte';
    import { onMount } from 'svelte';

    interface User {
        username: string;
        name: string;
    }

    interface Post {
        text: string;
    }

    interface PurchaseHistory {
        user_id?: string;
        user?: User;
        post_id?: string;
        post?: Post;
        amount: number;
        fee: number;
    }

    export let myPurcahseHistory: PurchaseHistory[];

      $: currentPage = myPurchaseHistory.current_page || 1;
    $: totalPages = myPurchaseHistory.last_page || 1;

    let open: boolean = false;
    let filterOption: string = 'All';
    let filteredData: PurchaseHistory[] = [];

    function toggleDropdown(): void {
        open = !open;
    }

    function selectOption(id: string): void {
        console.log('Id', id);

        filterOption = id;
        open = false;
        updateFilteredData(id, currentPage);
    }

    function updateFilteredData(id, page = 1) {
        const allData = myPurchaseHistory;
        if (id === 'Users') {
            filteredData = allData.filter((record) => record.user_id !== null && record.post_id === null);
        } else if (id === 'Posts') {
            filteredData = allData.filter((record) => record.post_id !== null && record.user_id === null);
        } else {
            filteredData = allData;
        }


        const start = (page - 1) * 6;
        const end = start + 6;
        filteredData = filteredData.slice(start, end);
    }

    function goToPage(page) {
        if (page >= 1 && page <= totalPages) {
            currentPage = page;
            inertia.get('/marketplace/my-history', { page: page, filter: filterOption }, {
                preserveState: true,
                preserveScroll: true,
            });
        }
    }

    onMount(() => {
        updateFilteredData(filterOption, currentPage);
    });
</script>

<svelte:component this="{MyMarket}" />

<div class="h-screen flex flex-col bg-gray-100">
    <div class="flex-1 flex items-start justify-center p-4">
        <div class="w-full max-w-4xl bg-white rounded-xl shadow-2xl border border-gray-200">
            <div class="table-wrapper">
                {#if filteredData.length > 0 || myPurchaseHistory.length > 0}
                    <div class="p-4">
                        <h2 class="text-2xl font-bold text-gray-800 mb-4">My Market</h2>
                        <div class="flex items-center justify-between mb-4">
                            <div class="relative block text-left">
                                <button
                                    type="button"
                                    class="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    aria-expanded="{open}"
                                    aria-haspopup="true"
                                    on:click="{toggleDropdown}"
                                >
                                    {filterOption}
                                    <svg
                                        width="20px"
                                        height="20px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        class="ml-2.5"
                                    ><path
                                        d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"
                                    ></path></svg>
                                </button>
                                <div
                                    class="absolute right-0 z-10 mt-2 w-32 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition-all duration-500"
                                    class:h-0="{!open}"
                                    class:h-auto="{open}"
                                    class:scale-50="{!open}"
                                    class:scale-100="{open}"
                                    class:opacity-0="{!open}"
                                    class:opacity-100="{open}"
                                >
                                    <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                        {#if filterOption === 'Users' || filterOption === 'Posts'}
                                            <a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" on:click="{() => selectOption('All')}">All</a>
                                        {/if}
                                        {#if filterOption !== 'Users'}
                                            <a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" on:click="{() => selectOption('Users')}">Users</a>
                                        {/if}
                                        {#if filterOption !== 'Posts'}
                                            <a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" on:click="{() => selectOption('Posts')}">Posts</a>
                                        {/if}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overflow-x-auto">
                            <table class="w-full table-auto border-collapse">
                                <thead>
                                    <tr class="border-b border-gray-200">
                                        <th class="py-3 px-4 text-left text-sm font-semibold text-gray-700">Sr.</th>
                                        {#if filterOption !== 'Posts'}
                                            <th class="py-3 px-4 text-left text-sm font-semibold text-gray-700">User</th>
                                        {/if}
                                        {#if filterOption !== 'Users'}
                                            <th class="py-3 px-4 text-left text-sm font-semibold text-gray-700">Post</th>
                                        {/if}
                                        <th class="py-3 px-4 text-left text-sm font-semibold text-gray-700">Amount</th>
                                        <th class="py-3 px-4 text-left text-sm font-semibold text-gray-700">Transaction Fee</th>
                                        <th class="py-3 px-4 text-left text-sm font-semibold text-gray-700">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {#each filteredData as myOffering, index}
                                        <tr class="hover:bg-gray-50 transition-colors duration-200">
                                            <td class="py-3 px-4 text-gray-600">{(currentPage - 1) * 6 + index + 1}</td>
                                            {#if filterOption !== 'Posts'}
                                                <td class="py-3 px-4 text-gray-800">
                                                    {#if myOffering.user_id}
                                                        <a href="/{myOffering.user.username}" class="text-indigo-600 hover:text-indigo-800 font-medium transition-colors" use:inertia>{myOffering.user.name}</a>
                                                    {:else}
                                                        <span class="text-gray-500">-</span>
                                                    {/if}
                                                </td>
                                            {/if}
                                            {#if filterOption !== 'Users'}
                                                <td class="py-3 px-4 text-gray-800">
                                                    {#if myOffering.post_id}
                                                        <a href="/{myOffering.post_id}" class="text-indigo-600 hover:text-indigo-800 font-medium transition-colors" use:inertia>{myOffering.post.text}</a>
                                                    {:else}
                                                        <span class="text-gray-500">-</span>
                                                    {/if}
                                                </td>
                                            {/if}
                                            <td class="py-3 px-4 text-gray-600">{myOffering.amount}</td>
                                            <td class="py-3 px-4 text-gray-600">{myOffering.fee}</td>
                                            <td class="py-3 px-4">
                                                <a href="/markets" class="inline-flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors" use:inertia>Invoice</a>
                                            </td>
                                        </tr>
                                    {/each}
                                </tbody>
                            </table>
                        </div>
                        <!-- Pagination Controls -->
                        <div class="p-4 flex justify-between items-center">
                            <button
                                on:click={() => goToPage(currentPage - 1)}
                                disabled={currentPage === 1}
                                class="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:bg-gray-400 disabled:cursor-not-allowed transition-colors"
                            >
                                Previous
                            </button>
                            <span class="text-sm text-gray-600">
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                on:click={() => goToPage(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                class="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:bg-gray-400 disabled:cursor-not-allowed transition-colors"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                {:else}
                    <p class="p-4 text-center text-gray-500">No Record Found</p>
                {/if}
            </div>
        </div>
    </div>
</div>
